<template>
  <div>
    <div class="fake-label text-capitalize">{{ getName }}</div>
    <div class="fake-content rounded border-dashed px-3 py-2 mt-1">{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    getName() {
      return this.name.replace(/([a-z])([A-Z])/g, "$1 $2");
    },
  },
};
</script>

<style lang="scss" scoped>
.border-dashed {
  border: 1px dashed rgba(42, 54, 59, 0.24) !important;
  border-radius: 4px;
}
</style>
