<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '800px'"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1" small><i class="fad fa-eye mr-2"></i> Audit Details</h3>
      <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
    </template>

    <div color="white" style="position: sticky; top: 0; z-index: 10">
      <v-tabs v-model="slideouts.update.tab" color="error" v-if="slideouts.update.active">
        <v-tab :key="0"> Basic Information </v-tab>
        <v-tab :key="1"> Parameters And Results</v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-container fluid class="pa-0">
      <v-tabs-items v-model="slideouts.update.tab">
        <v-tab-item :key="0">
          <v-container fluid class="pa-6 pt-3">
            <v-row class="my-0" v-if="selected">
              <v-col cols="12" sm="6" v-for="(item, key) in selected" :key="key">
                <audit-item :name="key" :value="item"></audit-item>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="1">
          <v-container fluid class="pa-6 pt-3">
            <v-row class="my-0" v-if="selected">
              <v-col cols="12">
                <div class="text-capitalize mb-1 fake-label">Parameters</div>
                <pre v-html="syntaxHighlight(this.parameters)"></pre>
              </v-col>
              <v-col cols="12">
                <div class="text-capitalize mb-1 fake-label">Result</div>
                <pre v-html="syntaxHighlight(this.result)"></pre>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeUpdateSlideout()">
          <i class="fal fa-chevron-left mr-2"></i>Close
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import AuditItem from "./AuditItem.vue";

export default {
  components: { AuditItem },
  data() {
    return {
      selected: null,
      parameters: null,
      result: null,
      slideouts: {
        update: {
          active: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onSlideoutClosing(e) {
      //reset the changes
      this.selected = {};
      this.parameters = null;
      this.result = null;
      this.$emit("close");
    },

    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    open(row) {
      this.selected = { ...row };
      this.parameters = this.selected.parameters;
      this.result = this.selected.result;
      delete this.selected.parameters;
      delete this.selected.result;
      delete this.selected.result;
      delete this.selected.id;
      this.slideouts.update.active = true;
      setTimeout(() => {
        this.slideouts.update.tab = 0;
      }, 250);
    },
    syntaxHighlight(json) {
      json = JSON.stringify(JSON.parse(json), undefined, 4);
      json = json.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
          var cls = "number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key";
            } else {
              cls = "string";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean";
          } else if (/null/.test(match)) {
            cls = "null";
          }
          return '<span class="' + cls + '">' + match + "</span>";
        }
      );
    },
  },
};
</script>
<style lang="scss">
pre {
  padding: 16px;
  border: 1px dashed rgba(42, 54, 59, 0.24) !important;
  border-radius: 4px;
  overflow-x: auto;
  max-height: 50vh;
  height: auto;

  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
}
</style>
