import Api from "../../Shared/services/api";

const baseUrl = "audits";
export default {
  get() {
    return Api().get(baseUrl);
  },
  query(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  }
};
